.signup-container {
  height: 100vh;
  width: 100%;
}

.signup-container .prompt-counter {
  position: absolute;
  margin: 0;
  text-align: right;
  padding-top: 1rem;
  padding-right: 1rem;
  font-family: "RAMDONE";
  -webkit-text-stroke: #87ceeb;
  -webkit-text-stroke-width: 1px;
  color: #f7fc9f;
  font-size: xxx-large;
  text-transform: uppercase;
}

.signup-container .volcano-bg {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
}

.signup-container *:not(.volcano-bg) {
  position: relative;
  z-index: 2;
}

.signup-container .congrats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.signup-container .congrats-container h1 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "GECKO";
  -webkit-text-stroke: #f7fc9f;
  -webkit-text-stroke-width: 2px;
  color: #fa6c1c;
  text-align: center;
  font-size: xx-large;
  max-width: 300px;
  width: 100%;
}

.signup-container .congrats-container h1 span {
  font-family: "RAMDONE";
  -webkit-text-stroke-width: 0.1px;
}

.signup-container .congrats-container h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "GECKO";
  -webkit-text-stroke: #f7fc9f;
  -webkit-text-stroke-width: 1px;
  color: #fa6c1c;
  text-align: center;
  font-size: x-large;
  max-width: 300px;
  width: 100%;
}
