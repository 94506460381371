@font-face {
  font-family: "GECKO";
  src: url(./Assets/Fonts/Gecko_Lunch.ttf);
}

@font-face {
  font-family: "RAMDONE";
  src: url(./Assets/Fonts/Ramdone.ttf);
}

button,
input,
a {
  appearance: none;
}

input[type="checkbox" i] {
  appearance: auto !important;
}

body,
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #000;
}

.hex-tagline {
  font-family: "GECKO";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.hex-tagline {
  font-size: large;
  position: fixed;
  bottom: 0.5rem;
  left: 0rem;
}

.hex-tagline img {
  border-radius: 100px;
  width: 50px;
}
