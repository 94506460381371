.signup-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.signup-container form div {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: center;
  gap: 10px;
}

.signup-container form div label {
  font-family: "GECKO";
  -webkit-text-stroke: #87ceeb;
  -webkit-text-stroke-width: 2px;
  color: #f7fc9f;
  font-size: xxx-large;
}

.signup-container form div input {
  background-color: #fa6c1c;
  border: none;
  border-radius: 15px;
  color: #fff;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  border: none;
}

.signup-container form div input[type="date"] {
  font-family: "GECKO";
  width: 200px;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
}

.signup-container form div input::placeholder {
  color: #fff;
  font-size: large;
  text-align: center;
}

.signup-container form div select {
  background-color: #fa6c1c;
  font-family: "RAMDONE";
  text-align: center;
  color: #fff;
  padding: 0.5rem;
  font-size: x-large;
  border: none;
  border-radius: 10px;
}

.signup-container form div input:focus {
  outline: none;
}

.signup-container form button {
  background-color: #fa6c1c;
  border-radius: 15px;
  color: #fff;
  font-family: GECKO;
  font-size: xx-large;
  text-align: center;
  border: none;
  text-transform: uppercase;
  padding: 1rem 2rem;
}

#input-form {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  transform: scale(0.1);
  bottom: -100%;
}

#input-form.animate {
  animation: slideIn 3s forwards;
  animation-delay: 1s;
}

#input-form input[name="ld_number"] {
  display: none;
}

#input-form.revert {
  transform: scale(0.1);
  bottom: 20%;
  opacity: 0;
}

.ld-number-generator {
  font-size: 1em !important;
}

#input-form .consent-container {
  position: absolute !important;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 10rem;
}

#input-form .consent-container input[type="checkbox" i] {
  border: 5px solid #000 !important;
  border-radius: 5px;
  margin-top: 0.8rem;
  margin-right: 0.1rem;
}

.signup-container .consent-container p {
  font-family: "GECKO";
  font-size: small;
}

.signup-container .consent-container span {
  font-family: "RAMDONE";
}

button:disabled {
  background-color: #aaa !important;
  cursor: not-allowed;
}

@keyframes slideIn {
  0% {
    transform: scale(0.1);
    bottom: 20%;
    z-index: 0;
    opacity: 0.5;
  }
  50% {
    transform: scale(0.4);
    bottom: 110%;
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    bottom: 40%;
    opacity: 1;
    z-index: 1;
  }
}
